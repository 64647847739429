import { Grid, List, ListItem,Box } from "@mui/material";
import "./index.css";
import GenericHeading from "../GenericHeading";
import fingerprint from "../../assests/fingerprint.svg";
import location from "../../assests/location.png";
import notifications from "../../assests/notifications.svg";
import rulesetting from "../../assests/rulesetting.svg";
import premiumScreen1 from "../../assests/premiumScreen1.webp";
import premiumScreen2 from "../../assests/premiumScreen2.webp";
import premiumScreen3 from "../../assests/premiumScreen3.webp";
import premiumScreen4 from "../../assests/premiumScreen4.webp";
import fingerprintHover from "../../assests/fingerprintHover.png";
import locationHover from "../../assests/locationHover.png";
import notificationsHover from "../../assests/notificationsHover.png";
import rulesettingHover from "../../assests/rulesettingHover.png";
import vLogo from "../../assests/vLogo.png";
import { useEffect, useState } from "react";
const bulletPointData = [
  {
    id: 1,
    bulletLogo: location,
    hoverBulletLogo: locationHover,
    bulletHeading: "Range of fitness and wellness categories",
    bulletPoint:
      "Explore 25+ fitness and wellness categories of all skill levels.",
    image: premiumScreen1,
  },
  {
    id: 2,
    bulletLogo: rulesetting,
    hoverBulletLogo: rulesettingHover,
    bulletHeading: "Manage your fitness activities",
    bulletPoint:
      "Easily view upcoming classes and events in your area tailored to your personal preferences and goals.",
    image: premiumScreen2,
  },
  {
    id: 3,
    bulletLogo: notifications,
    hoverBulletLogo: notificationsHover,
    bulletHeading: "Know before You Go",
    bulletPoint:
      "In-depth class details provide the confidence you need to sweat it out, without sweating the details.",
    image: premiumScreen3,
  },
  {
    id: 4,
    bulletLogo: fingerprint,
    hoverBulletLogo: fingerprintHover,
    bulletHeading: "Find Your Community",
    bulletPoint:
      "Filter through your favorites to find the best fit to get fit.",
    image: premiumScreen4,
  },
];

const BulletPoint = ({
  bulletLogo,
  bulletHeading,
  bulletPoint,
}: {
  bulletLogo: string;
  bulletHeading: string;
  bulletPoint: string;
}) => {
  return (
    <Grid container className="expm">
      <Grid item lg={1} sm={1} md={1} className="image-wrapper">
        <img src={bulletLogo} alt="tick" className="bulletLogo" loading="lazy" />
      </Grid>
      <Grid
        item
        className="bullet-point-section"
        lg={11}
        sm={11}
        md={11}
        style={{ paddingInline: "2rem" }}
      >
        <Grid className="bullet-point-heading">{bulletHeading}</Grid>
        <Grid className="bullet-point-text" sm={10}>
          {bulletPoint}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ExplorePremiumFeatures = () => {
  const [selectedLink, setSelectedLink] = useState<number>(
    bulletPointData[0].id
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedLink((prevLink) => (prevLink % 4) + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Grid container className="explore-premium-section">
      <Grid item xs={12} sm={12} md={12} lg={12} className="heading-section">
        <GenericHeading
          heading="Premium Features, Free Download"
          paragraph="Explore the most robust fitness platform on the market."
          color="#ffffff"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} className="premium-content">
        <Grid item xs={12} sm={8} md={8} lg={8} className="premium-heading">
          <List className="list-item">
            {bulletPointData.map((bullet, index) => {
              return (
                <ListItem
                key={index}
                  onClick={() => setSelectedLink(bullet.id)}
                  className={`link ${
                    selectedLink === bullet.id ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <Box className="fore-desktop">
                    <BulletPoint
                      bulletLogo={
                        selectedLink === bullet.id
                          ? bullet.hoverBulletLogo
                          : bullet.bulletLogo
                      }
                      bulletHeading={bullet.bulletHeading}
                      bulletPoint={bullet.bulletPoint}
                    />
                  </Box>
                  <Box className="fore-mobile">
                    <BulletPoint
                      bulletLogo={bullet.hoverBulletLogo}
                      bulletHeading={bullet.bulletHeading}
                      bulletPoint={bullet.bulletPoint}
                    />
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          className="premium-image-container"
        >
          <img src={vLogo} alt="vLogo" className="vLogo" loading="lazy"/>
          <img
            src={
              bulletPointData.find((item) => item.id === selectedLink)?.image
            }
            alt="Premium Screen"
            className="premiumScreen1"
            loading="lazy"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
