import gripstudiologo from "../../assests/logo.svg";
import "./index.css";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { HeaderChipSelection } from "../HeaderChipSelection/HeaderChipSelection";
import { useTheme } from "@mui/material/styles";
import Swiper  from "../SwiperComponent";

export interface HeaderSectionProps {
  scrolled?: boolean,
  isUsers: boolean;
  setIsUsers: React.Dispatch<React.SetStateAction<boolean>>;
}
const handleAndroidClick = () => {
  const url = 'https://play.google.com/store/apps/details?id=com.gripstudio.app&pli=1';
  window.open(url, '_blank');
};
const handleAppleClick = () => {
  const url = 'https://apps.apple.com/in/app/gripstudio/id6473517079';
  window.open(url, '_blank');
};
const handleDownload = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes('android')) {
    const url = 'https://play.google.com/store/apps/details?id=com.gripstudio.app&pli=1';
    window.open(url, '_blank');
  } else {
    const url = 'https://apps.apple.com/in/app/gripstudio/id6473517079';
    window.open(url, '_blank');
  }
}
function HeaderSection({ isUsers, setIsUsers }: HeaderSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container>
      <Grid item xs={12} className="header-section">
        <Grid container className="header page-section" maxWidth={'90%'} marginInline={'auto'}>
          <Grid item xs={12} sm={12} md={3} lg={3} className={"logo"}>
            <img
              src={gripstudiologo}
              alt="studio-logo"
              className="gripstudioLogo-header"
              loading="lazy"
            />
          </Grid>
          {isMobile ? (
            <HeaderChipSelection isUsers={isUsers} setIsUsers={setIsUsers} />
          ) : (
            <Grid item xs={12} md={9} lg={9}>
              <ul className="header-list">
                <li
                  className={`header-tag ${isUsers ? "active" : ""}`}
                  onClick={() => setIsUsers(true)}
                >
                 For Guest & Users
                </li>
                <li
                  className={`header-tag ${!isUsers ? "active" : ""}`}
                  onClick={() => setIsUsers(false)}
                >
                  For Gyms, Trainers, and Wellness Studios
                </li>
                <li className="header-tag">
                  <Button className="active-btn download-button" onClick={handleDownload}>
                    Download Now
                  </Button>
                </li>
              </ul>
            </Grid>
          )}
        </Grid>
        <Grid className="slider-heading h1">
          Join the fitness and <br /> wellness community
          <Grid className="store-button">
            <button className="app-button" onClick={handleAndroidClick}>
              Play Store download
            </button>
            <button className="app-button" onClick={handleAppleClick}>
              App Store download
            </button>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className="slider-class">
        {!isMobile && <Swiper/>}
          <Grid item className="mobile-image">
            {isUsers ? (
              <img src={"https://cdn.gripstudio.app/screen1.png"} alt="mobile-1" />
            ) : (
              <img loading="lazy" src={"https://cdn.gripstudio.app/IpadProTrainer.png"} alt="mobile-2" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderSection;
